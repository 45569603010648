"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rateNamesImgs = exports.entrypointExitPointMap = exports.entrypointData = exports.points = exports.tollFacilityData = exports.vehicleTypeData = void 0;
exports.vehicleTypeData = [
    { value: 'class2', label: 'Two-axle vehicles', img: '/app/plugins/fahrenheit-toll-rate-calculator/images/class2.svg' },
    { value: 'class3', label: 'Three-axle vehicle', img: '/app/plugins/fahrenheit-toll-rate-calculator/images/class3.svg' },
    { value: 'class4', label: 'Four-axle vehicle', img: '/app/plugins/fahrenheit-toll-rate-calculator/images/class4.svg' },
    { value: 'class5', label: 'Five-axle vehicle', img: '/app/plugins/fahrenheit-toll-rate-calculator/images/class5.svg' },
    { value: 'class6', label: 'Six-axle vehicle', img: '/app/plugins/fahrenheit-toll-rate-calculator/images/class6.svg' },
    { value: 'class8', label: 'All motorcycles', img: '/app/plugins/fahrenheit-toll-rate-calculator/images/class8.svg' }
];
exports.tollFacilityData = [
    { value: 'icc', label: 'Intercounty Connector (ICC)/MD 200' },
    { value: 'jfk', label: 'John F. Kennedy Memorial Highway (I-95)' },
    { value: 'etl', label: 'I‐95 Express Toll Lanes (I-95)' },
    { value: 'tjh', label: 'Thomas Hatem Memorial Bridge (US 40)' },
    { value: 'bht', label: 'Baltimore Harbor Tunnel Thruway (I-895)' },
    { value: 'fsk', label: 'Francis Scott Key Bridge (I-695)' },
    { value: 'fmt', label: 'Fort McHenry Tunnel (I-95)' },
    { value: 'byb', label: 'Bay Bridge (US 50/301)' },
    { value: 'nmb', label: 'Nice/Middleton Bridge (US 301)' }
];
exports.points = [
    { value: 'i370', label: 'Interstate 370' },
    { value: 'md97', label: 'Georgia Ave (MD 97)' },
    { value: 'md182', label: 'Layhill Rd (MD 182)' },
    { value: 'md650', label: 'New Hampshire Ave (MD 650)' },
    { value: 'us29', label: 'Columbia Pike (US 29) / Briggs Chaney Rd' },
    { value: 'i95', label: 'Interstate 95' },
    { value: 'us1', label: 'Konterra Drive / US 1' }
];
exports.entrypointData = ['i370', 'md97', 'md182', 'md650', 'us29', 'i95', 'us1'];
exports.entrypointExitPointMap = [
    { entrypoint: 'i370', exitpoints: ['md97', 'md182', 'md650', 'us29', 'i95', 'us1'] },
    { entrypoint: 'md97', exitpoints: ['md182', 'md650', 'us29', 'i95', 'us1', 'i370'] },
    { entrypoint: 'md182', exitpoints: ['md650', 'us29', 'i95', 'us1', 'i370', 'md97'] },
    { entrypoint: 'md650', exitpoints: ['us29', 'i95', 'us1', 'i370', 'md97', 'md182'] },
    { entrypoint: 'us29', exitpoints: ['i95', 'us1', 'i370', 'md97', 'md182', 'md650'] },
    { entrypoint: 'i95', exitpoints: ['us1', 'i370', 'md97', 'md182', 'md650', 'us29'] },
    { entrypoint: 'us1', exitpoints: ['i370', 'md97', 'md182', 'md650', 'us29', 'i95'] }
];
exports.rateNamesImgs = [
    { name: 'E-ZPass Maryland', img: '/app/plugins/fahrenheit-toll-rate-calculator/images/e-zpass-icon.png' },
    { name: 'Out of State E-ZPass', img: '/app/plugins/fahrenheit-toll-rate-calculator/images/e-zpass-icon.png' },
    { name: 'Pay-By-Plate', img: '/app/plugins/fahrenheit-toll-rate-calculator/images/pay-by-plate-icon.png' },
    { name: 'Video Tolls', img: '/app/plugins/fahrenheit-toll-rate-calculator/images/video-toll-icon.png' },
    { name: 'Discount Plan (if available)', img: '/app/plugins/fahrenheit-toll-rate-calculator/images/discount-icon.png' }
];
